var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    staticClass: "login__main"
  }, [_c('section', [_c('h2', [_vm._v(_vm._s(_vm.showForgottenPassword || _vm.showFinalMessage ? _vm.$t('login.recoverPassword') : _vm.$t('login.loginOrSignup')))]), _vm._v(" "), !_vm.showForgottenPassword && !_vm.showFinalMessage ? _c('div', {
    staticClass: "login__content login"
  }, [_c('Button', {
    staticClass: "google-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.loginGoogle.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "pi pi-google"
  }), _vm._v(" "), _c('span', {
    domProps: {
      "textContent": _vm._s(_vm.$t('login.accessWithGoogle'))
    }
  })]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.$t('login.or')))]), _vm._v(" "), _c('form', {
    staticStyle: {
      "padding-top": "12px"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.loginUser.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "input-container"
  }, [_c('InputText', {
    class: {
      'p-invalid': _vm.submitTried && (_vm.email === '' || _vm.emailError)
    },
    attrs: {
      "id": "emailinput",
      "aria-describedby": "emailinput-help",
      "type": "text",
      "placeholder": _vm.$t('login.email')
    },
    on: {
      "input": function ($event) {
        _vm.submitTried = false;
      }
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _vm._v(" "), _vm.submitTried && (_vm.email === '' || _vm.emailError) ? _c('small', [_vm._v("\n            " + _vm._s(_vm.email === '' ? _vm.$t('login.emailRequired') : _vm.emailError) + "\n          ")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "input-container"
  }, [_c('Password', {
    class: {
      'p-invalid': _vm.submitTried && _vm.password === ''
    },
    attrs: {
      "id": "passwordinput",
      "toggle-mask": "",
      "feedback": false,
      "type": "password",
      "aria-describedby": "passwordinput-help",
      "placeholder": _vm.$t('login.password')
    },
    on: {
      "input": function ($event) {
        _vm.submitTried = false;
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm._v(" "), _vm.submitTried && _vm.password === '' ? _c('small', {
    staticClass: "p-invalid",
    attrs: {
      "id": "passwordinput-help"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('login.passwordRequired'))
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.error ? _c('small', {
    staticClass: "wrong-inputs",
    staticStyle: {
      "margin": "auto"
    },
    domProps: {
      "textContent": _vm._s(_vm.error)
    }
  }) : _vm._e(), _vm._v(" "), _c('Button', {
    staticClass: "p-button-rounded p-full",
    attrs: {
      "label": _vm.$t('login.accessWithCredentials'),
      "type": "submit"
    }
  })], 1), _vm._v(" "), _c('Button', {
    staticClass: "login__forgotten",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showForgottenPassword = true;
        _vm.email = '';
        _vm.error = '';
        _vm.submitTried = false;
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('login.forgottenPassword')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "login__noaccount"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('login.noAccount')) + " "), _c('a', {
    staticClass: "goToSignup",
    on: {
      "click": _vm.userSignup
    }
  }, [_vm._v(_vm._s(_vm.$t('login.signup')))])]), _vm._v(" "), _c('div', {
    staticClass: "login__terms",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('login.continueWithTems'))
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.showForgottenPassword && !_vm.showFinalMessage ? _c('div', {
    staticClass: "login__content recover"
  }, [_c('form', {
    staticStyle: {
      "padding-top": "0px"
    }
  }, [_c('div', {
    staticClass: "field"
  }, [_c('label', {
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t('login.email')) + " *")]), _vm._v(" "), _c('InputText', {
    class: {
      'p-invalid': _vm.submitTried && (_vm.email === '' || _vm.emailError) || _vm.error
    },
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('login.insertEmailToRequestNewPassword')
    },
    on: {
      "input": function ($event) {
        _vm.submitTried = false;
      }
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _vm._v(" "), _vm.submitTried && (_vm.email === '' || _vm.emailError) ? _c('small', [_vm._v("\n            " + _vm._s(_vm.email === '' ? _vm.$t('login.emailRequired') : _vm.emailError) + "\n          ")]) : _vm._e()], 1), _vm._v(" "), _c('Button', {
    staticClass: "p-button-rounded p-full",
    attrs: {
      "label": _vm.$t('login.requestNewPassword')
    },
    on: {
      "click": _vm.requestPassword
    }
  }), _vm._v(" "), _c('Button', {
    staticClass: "login__forgotten",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.showForgottenPassword = false;
        _vm.submitTried = false;
        _vm.emailError = '';
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t('login.backToLogin')) + "\n        ")])], 1)]) : _vm._e(), _vm._v(" "), _vm.showFinalMessage ? _c('div', {
    staticClass: "login__finalmessage"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t('login.anEmailWasSent')))]), _vm._v(" "), _c('Button', {
    staticClass: "login__forgotten",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.resetForms.apply(null, arguments);
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('login.backToLogin')) + "\n      ")])], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }