
import Vue from 'vue'

import { mapActions, mapGetters } from 'vuex'
// import BoosterPicture from '../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'

if (process.client) {
  const VueCryptojs = require('vue-cryptojs').default
  const GAuth = require('vue-google-oauth2').default
  Vue.use(VueCryptojs)
  Vue.use(GAuth, {
    clientId: '75229862004-unrkhhktd5ip381qro36vig0hueisv2j.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
  })
}

declare global {
    interface Window {
        AppleID: any
    }
}

export default Vue.extend({
  name: 'Login',
  components: {
    Password: () => import('primevue/password'),
    InputText: () => import('primevue/inputtext'),
    Button: () => import('primevue/button')
  },
  layout: 'footer',
  data() {
    return {
      picture: {
        sources: [{ src: './assets/images/test/registration/platform_login_desk-420.png', sizes: { sm: '420px', md: '420px', lg: '420px', xl: '420px', xxl: '420px' } }],
        title: 'Image Title',
        alt: 'Image Alt',
        class: 'decoration'
      },
      firstLogin: false,
      submitTried: false,
      email: '',
      password: '',
      error: '',
      emailError: '',
      showForgottenPassword: false,
      showFinalMessage: false,
      $gAuth: null,
      $gtm: null,
      showPassword: false,
      suggestion: null,
      secret: 'CVing secret'

    }
  },
  head() {
    return {
      title: 'Login - CVing',
      meta: [{ hid: 'robots', name: 'robots', content: 'noindex,follow' }]
    }
  },
  computed: {
    ...mapGetters({ orpSuggestion: 'radar/getOrpSuggestion' })
  },
  created() {
    if (this.$auth?.loggedIn) {
      this.$router.push(this.localePath('index'))
    }
    this.firstLogin = !!this.$route.query.first_login
    if (this.orpSuggestion?.cid) {
      this.track('login', 'start', '')
    }
  },
  mounted() {
    this.$nextTick(() => {
      window?.AppleID?.auth.init({
        clientId: 'com.cving.appleidlogin', // This is the service ID we created.
        scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
        redirectURI: 'https://gcpstaging.cving.com/v1/auth/get-user-data', // As registered along with our service ID
        state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
        usePopup: true // Important if we want to capture the data apple sends on the client side.
      })
    })
  },
  methods: {
    ...mapActions({
      setOrpOutcome: 'radar/setOrpOutcome',
      setIsFirstLogin: 'notifications/setIsFirstLogin'
    }),
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    userSignup() {
      if (this.orpSuggestion?.cid) {
        this.track('login', 'end', 'signup')
      }
      this.$router.push({ name: `signup___${this.$i18n.locale}` })
    },
    track(stepName: string, stepStage: string, actionStep: string) {
      const ita = this.orpSuggestion?.company?.coid === 594
      const data = {
        company_name: this.orpSuggestion?.campaign?.company?.co_name,
        job_application_name: this.orpSuggestion?.campaign?.title,
        job_application_place: this.orpSuggestion?.campaign?.city,
        is_stage: this.orpSuggestion?.campaign?.contract_type === 8,
        verified: this.orpSuggestion?.campaign?.verified,
        orp: this.orpSuggestion?.campaign?.verified && !this.orpSuggestion?.campaign?.expired && !ita && this.orpSuggestion?.campaign?.p2p && this.$config.p2p,
        orp_value: Number(this.orpSuggestion?.campaign?.unlockamount),
        page_url: this.$router.currentRoute.fullPath,
        page_title: 'Login',
        step_name: stepName,
        step_stage: stepStage,
        action_step: actionStep,
        logged: this.$auth?.loggedIn
      }
      console.log(`event: orp_program_v2, step name: ${stepName}, step stage: ${stepStage}, action: ${actionStep}, logged: ${this.$auth?.loggedIn}`)
      this.$gtm.push({ event: 'orp_program_v2', event_data: data })
    },
    async loginUser() {
      this.submitTried = true
      if (!this.email.trim() || !this.password.trim()) {
        return
      }
      if (!this.validateEmail(this.email)) {
        this.emailError = this.$t('login.notValidEmail').toString()
        return
      }
      try {
        this.submitTried = false
        this.$nuxt.$loading?.start()
        this.error = ''

        let login = null
        login = await this.$auth.loginWith('local', { data: { email: this.email.trim(), password: this.password.trim(), account: 0, dlt: '', id_token: '' } })
        const encryptedEmail = (this as any).$CryptoJS.AES.encrypt(this.email.trim(), this.secret).toString()

        this.$gtm.push({ event: 'login', event_data: { login_platform: 'main_site', login_method: 'email', user_id: encryptedEmail, page_url: this.$router.currentRoute.fullPath, page_title: 'Login - CVing' } })

        if (login && this.orpSuggestion?.cid) {
          this.track('login', 'end', 'login')
        }

        this.$nuxt.$loading?.finish()
        // questo è il caso in cui voglio fare un suggerimento, e mi loggo per poterlo fare
        // && !login.data.redirect_to.includes('suggest')
        if (this.orpSuggestion?.cid) {
          this.$router.push(
            this.localePath({
              name: 'radar-city-slug',
              params: {
                city: this.orpSuggestion.campaign.city === '' ? 'no-city' : this.orpSuggestion.campaign.cityslug,
                slug: this.orpSuggestion.campaign.slug
              },
              query: {
                ...this.orpSuggestion.query,
                id: this.orpSuggestion.cid,
                verified: this.orpSuggestion.campaign.verified,
                ea: this.orpSuggestion.campaign.ea
              }
            })
          )
        } else if (login.data.redirect_to.includes('suggest')) {
          // questo è il caso in cui voglio fare un suggerimento, e mi loggo DOPO ESSERMI REGISTRATO
          const suggestCodeMatch = login.data.redirect_to.match(/suggest\/(\d+)/)
          if (suggestCodeMatch) {
            const suggestCode = suggestCodeMatch[1]
            console.log('Codice suggest trovato:', suggestCode)
            this.setOrpOutcome({ suggestCode })
            this.$router.push(this.localePath({ name: 'suggest' }))
          }
        } else if (login.data.redirect_to === 'mbti_result') {
          this.$router.push(this.localePath({ name: 'user-settings-personality-test' }))
        }
      } catch (error:any) {
        this.$nuxt.$loading?.finish()
        if (this.email.trim() !== '' && this.password.trim() !== '') {
          this.error = error.response?.status === 401 ? this.$t('login.wrongUsername').toString() : error.response?.data.error || 'Errore durante il login'
        }
      }
    },
    async loginGoogle() {
      try {
        this.$nuxt.$loading?.start()
        this.error = ''
        const googleUser = await this.$gAuth.signIn()
        const idToken = googleUser.getAuthResponse().id_token
        const params = { email: '', password: '', account: 3, dlt: '', id_token: idToken }
        const login = await this.$axios.post('/proxyapi/login-google', params)
        this.$auth.setUserToken(login.data.token)
        const encryptedEmail = (this as any).$CryptoJS.AES.encrypt(this.email.trim(), this.secret).toString()

        this.$gtm.push({ event: 'login', event_data: { login_platform: 'main_site', login_method: 'google', user_id: encryptedEmail, page_url: this.$router.currentRoute.fullPath, page_title: 'Login - CVing' } })

        await this.getUserData()
        console.log(this.orpSuggestion?.cid)
        if (login && this.orpSuggestion?.cid) {
          this.track('login', 'end', 'login')
        }

        if (login.data.redirect_to.includes('suggest')) {
          const suggestCodeMatch = login.data.redirect_to.match(/suggest\/(\d+)/)
          if (suggestCodeMatch) {
            const suggestCode = suggestCodeMatch[1]
            console.log('Codice suggest trovato:', suggestCode)
            this.setOrpOutcome({ suggestCode })
          }
          this.$router.push(this.localePath({ name: 'suggest' }))
        } else if (login.data.redirect_to === 'mbti_result') {
          this.$router.push(this.localePath({ name: 'user-settings-personality-test' }))
        }
      } catch (error:any) {
        this.error = error.response?.status === 401 ? this.$t('login.wrongUsername').toString() : error === false ? 'Google not defined' : 'Errore durante il login'
      } finally {
        this.$nuxt.$loading?.finish()
      }
    },
    async loginApple () {
      try {
        this.$nuxt.$loading.start()
        this.error = ''
        const response = await window.AppleID.auth.signIn()
        console.log(response)
        const params = {
          email: '',
          password: '',
          account: 3,
          dlt: '',
          apple_token: response.authorization.code
        }
        const login = await this.$axios.post('/proxyapi/login-apple', params)
        this.$auth.setUserToken(login.data.token)
        await this.$axios.get('/proxyapi/auth/get-csrf-token')
        await this.$axios.get('/proxystorage/auth/get-csrf-token')
        const encryptedEmail = (this as any).$CryptoJS.AES.encrypt(this.email.trim(), this.secret).toString()

        this.$gtm.push({
          event: 'login',
          event_data: {
            login_platform: 'main_site',
            login_method: 'apple',
            user_id: encryptedEmail,
            page_url: this.$router.currentRoute.fullPath,
            page_title: 'Login - CVing'
          }
        })
        await this.getUserData()

        if (login.data.redirect_to === '/suggest') {
          console.log('redirect')
          this.$router.push(this.localePath({ name: 'suggest' }))
        }
        if (login.data.redirect_to === 'mbti_result') {
          this.$router.push(this.localePath({ name: 'user-settings-personality-test' }))
        }
        if (this.orpSuggestion && this.orpSuggestion.cid) {
          this.$router.push(this.localePath({ name: 'suggest' }))
        }
      } catch (error : any) {
        if (error.response !== undefined && error.response.status === 401) {
          this.error = this.$t('login.wrongUsername').toString()
        }
        if (error === false) {
          this.error = 'Google not defined'
        }
        this.$nuxt.$loading.finish()
      }
    },
    async getUserData() {
      try {
        const user = await this.$axios.get('/proxyapi/auth/get-user-data')
        this.$auth.setUser(user.data)
      } catch (error) {
        console.error(error)
      }
      this.$nuxt.$loading?.finish()
    },
    async requestPassword() {
      this.submitTried = true
      if (!this.validateEmail(this.email)) {
        this.emailError = this.$t('login.notValidEmail').toString()
        return
      }
      if (this.email) {
        this.submitTried = false
        this.error = ''
        this.$nuxt.$loading?.start()
        try {
          await this.$axios.post('/proxyapi/ask-password-reset-candidates', { email: this.email })
        } catch (error:any) {
        } finally {
          this.showFinalMessage = true
          this.$nuxt.$loading?.finish()
        }
      }
    },
    resetForms() {
      this.showFinalMessage = false
      this.showForgottenPassword = false
    }
  }
})
